import React from 'react';
import { Line } from 'react-chartjs-2';
import './LineChar.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, startOfWeek, addDays } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChar = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Lượt truy cập trong 7 ngày gần đây',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: '7 Ngày Gần Đây',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Views',
        },
        min: 0,
        max: 100,
      },
    },
  };

  const generateCurrentWeekDates = () => {
    const now = new Date();
    const start = startOfWeek(now, { weekStartsOn: 1 }); // Monday as the start of the week
    return Array.from({ length: 7 }, (_, i) =>
      format(addDays(start, i), 'dd/MM')
    );
  };

  const data = {
    labels: generateCurrentWeekDates(),
    datasets: [
      {
        label: 'Views',
        data: [10, 25, 5, 45, 55, 25, 75],
        borderColor: '#25b34b',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
      },
    ],
  };

  return (
    <>
      <Line
        options={options}
        data={data}
      />
      <div className="line-views">
        <div className="line-table">
          <h3>Tổng quan</h3>
          <p>Work: 25 views</p>
          <p>About: 25 views</p>
          <p>Blog: 25 views</p>
          <p>Expertise: 25 views</p>
          <p>Thoughts: 25 views</p>
        </div>
      </div>
    </>
  );
};

export default LineChar;

import React, { useEffect, useState } from 'react';
import FormAddBlog from './FormBlog/FormAddBlog';
import ReactPaginate from 'react-paginate';
import FormEditBlog from './FormBlog/FormEditBlog';
import FormBlogHeading from './BlogHeader/FormBlogHeading';
import FormBlogContent from './BlogHeader/FormBlogContent';
import cloudinaryDelete from '../../../server/delete.js';

const Blog = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [openFormBlog, setOpenFormBlog] = useState(false);
  const [openFormEditBlog, setOpenFormEditBlog] = useState(false);
  const [post, setPost] = useState({});
  const [blogHeader, setBlogHeader] = useState({});
  const [formBlogContent, setFormBlogContent] = useState(false);
  const [formBlogHeading, setFormBlogHeading] = useState(false);

  const handleOpenFormBlog = () => {
    setOpenFormBlog(true);
  };
  const handleCloseFormBlog = () => {
    setOpenFormBlog(false);
  };

  const handleOpenFormEditBlog = (post) => {
    setOpenFormEditBlog(true);
    setPost(post);
  };
  const handleCloseFormEditBlog = () => {
    setOpenFormEditBlog(false);
  };

  const handleOpenFormBlogHeading = () => {
    setFormBlogHeading(true);
    setFormBlogContent(false);
  };
  const handleCloseFormBlogHeading = () => {
    setFormBlogHeading(false);
  };

  const handleOpenFormBlogContent = () => {
    setFormBlogContent(true);
    setFormBlogHeading(false);
  };
  const handleCloseFormBlogContent = () => {
    setFormBlogContent(false);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch('https://api.chuckmotion.com/blog/get-blog');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBlogs(data);
    };
    const fetchBlogHeader = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/blog/get-blog-header'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBlogHeader(data);
    };

    fetchProjects();
    fetchBlogHeader();
    setOpenFormBlog(false);
  }, [refreshKey]);

  const deleteProject = (post) => {
    if (window.confirm('Bạn muốn xóa bài viết này không?')) {
      fetch(`https://api.chuckmotion.com/blog/delete-blog/${post.post_id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              'https://api.chuckmotion.com/blog/get-blog'
            );
            const data = await res.json();
            setPost(data);
          };
          cloudinaryDelete(post.id_thumbnail);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody className="project-list">
        {itemInPage &&
          itemInPage.slice(0, 10).map((project) => (
            <tr>
              <td>
                <p dangerouslySetInnerHTML={{ __html: project.heading }} />
              </td>
              <td>
                <img
                  src={project.thumbnail}
                  title="Blog Image"
                  alt="ProjectImage"
                  className="project-image"
                />
              </td>
              <td>{project.status === 1 ? 'Hoàn thành' : 'Ẩn'}</td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditBlog(project)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteProject(project)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(blogs.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={blogs.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h1>Bài viết</h1>
      <h2>Blog Secsion</h2>
      {formBlogHeading && (
        <>
          <FormBlogHeading
            heading={blogHeader}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormBlogHeading}>Đóng</span>
          </div>
        </>
      )}
      {formBlogContent && (
        <>
          <FormBlogContent
            content={blogHeader}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormBlogContent}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Blog heading</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormBlogHeading}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Blog content</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormBlogContent}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {openFormEditBlog ? (
        <>
          <FormEditBlog
            blog={post}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditBlog}>Đóng</span>
          </div>
        </>
      ) : null}
      <h2>Danh sách bài viết</h2>
      <table>
        <thead>
          <tr>
            <th>Tên dự án</th>
            <th>Hình thumbnail</th>
            <th>Trạng thái</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <PageItem pageSize={8} />
      </table>
      <div className="project-add">
        <span onClick={handleOpenFormBlog}>Thêm bài viết</span>
      </div>
      {openFormBlog ? (
        <>
          <FormAddBlog onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormBlog}>Đóng</span>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Blog;

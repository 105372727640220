import React, { useEffect, useState, lazy, Suspense } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Loading from './Components/Loading/Loading';
import Loaded from './Components/Loading/Loaded';
const Home = lazy(() => import('./Components/Home/Home'));
const Header = lazy(() => import('./Components/Header/Header'));
const Footer = lazy(() => import('./Components/Footer/Footer'));
const WorkDetail = lazy(() => import('./Components/WorkDetail/WorkDetail'));
const Expertise = lazy(() => import('./Components/Expertise/Expertise'));
const BlogDetail = lazy(() => import('./Components/BlogDetail/BlogDetail'));
const Blog = lazy(() => import('./Components/Blog/Blog'));
const Process = lazy(() => import('./Components/Process/Process'));
const Enquires = lazy(() => import('./Components/Enquires/Enquires'));
const Studio = lazy(() => import('./Components/Studio/Studio'));

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 1.5,
};

const App = ({ headerMeta }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [menu, setMenu] = useState({});
  const [infomation, setInfomation] = useState({});
  const [social, setSocial] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [menuRes, infoRes, socialRes] = await Promise.all([
          fetch('https://api.chuckmotion.com/header/get-menu'),
          fetch('https://api.chuckmotion.com/header/get-info'),
          fetch('https://api.chuckmotion.com/header/get-social'),
        ]);

        const [menuText, infoText, socialText] = await Promise.all([
          menuRes.text(),
          infoRes.text(),
          socialRes.text(),
        ]);

        const menuData = menuText ? JSON.parse(menuText) : {};
        const infoData = infoText ? JSON.parse(infoText) : {};
        const socialData = socialText ? JSON.parse(socialText) : {};

        setMenu(menuData);
        setInfomation(infoData);
        setSocial(socialData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <AnimatePresence mode="wait">
      <Loaded />
      <div className="wrapper">
        <main className="main">
          <Suspense fallback={<Loading />}>
            <Routes
              location={location}
              key={location.pathname}>
              <Route
                path="/"
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <Home />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_1.toLowerCase()}`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <Home />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_1.toLowerCase()}/:slug`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <WorkDetail />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_2.toLowerCase()}`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <Expertise />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_6.toLowerCase()}/:slug`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <BlogDetail />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_6.toLowerCase()}`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <Blog />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_3.toLowerCase()}`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <Process />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_5.toLowerCase()}`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <Enquires />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
              <Route
                path={`/${menu.menu_4.toLowerCase()}`}
                element={
                  <AnimatedPage>
                    <Header
                      menu={menu}
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                    <Studio />
                    <Footer
                      headerMeta={headerMeta}
                      infomation={infomation}
                      social={social}
                    />
                  </AnimatedPage>
                }
              />
            </Routes>
          </Suspense>
        </main>
      </div>
    </AnimatePresence>
  );
};

const AnimatedPage = ({ children }) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}>
      {children}
    </motion.div>
  );
};

export default App;

import React from 'react';
// import { useSelector } from 'react-redux';
import './Nav.css';
// import { Link } from 'react-router-dom';

const Nav = () => {
  //   const daDangNhap = useSelector((state) => state.auth.daDangNhap);
  //   const user = useSelector((state) => state.auth.user);

  return (
    <div className="navAdmin">
      <div className="nav">
        <button id="menu-btn">
          <span className="material-icons-sharp">menu</span>
        </button>
        <div className="dark-mode">
          <span className="material-icons-sharp active">light_mode</span>
          <span className="material-icons-sharp">dark_mode</span>
        </div>

        <div className="profile">
          <div className="info">
            {/* <Link to={`profile/${user.id_user}`}>
              <p>Hey, {daDangNhap === true ? <b>{user.username}</b> : ''}</p> */}
            <small className="text-muted">Admin</small>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;

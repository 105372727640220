import React, { useEffect, useState } from 'react';
import FormInfomation from './FormHeader/FormInfomation';
import FormLogo from './FormHeader/FormLogo';
import FormIcon from './FormHeader/FormIcon';
import FormMenu from './FormHeader/FormMenu';
import FormSocial from './FormHeader/FormSocial';

const Header = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [infomation, setInfomation] = useState({});
  const [formInfomation, setFormInfomation] = useState(false);
  const [meta, setMeta] = useState({});
  const [formLogo, setFormLogo] = useState(false);
  const [formIcon, setFormIcon] = useState(false);
  const [menu, setMenu] = useState({});
  const [formMenu, setFormMenu] = useState(false);
  const [social, setSocial] = useState({});
  const [formSocial, setFormSocial] = useState(false);

  const handleOpenFormEditInfomation = () => {
    setFormInfomation(true);
    setFormIcon(false);
    setFormLogo(false);
    setFormMenu(false);
    setFormSocial(false);
  };
  const handleCloseFormEditInfomation = () => {
    setFormInfomation(false);
  };

  const handleOpenFormEditLogo = () => {
    setFormLogo(true);
    setFormIcon(false);
    setFormInfomation(false);
    setFormMenu(false);
    setFormSocial(false);
  };
  const handleCloseFormEditLogo = () => {
    setFormLogo(false);
  };

  const handleOpenFormEditIcon = () => {
    setFormIcon(true);
    setFormLogo(false);
    setFormInfomation(false);
    setFormMenu(false);
    setFormSocial(false);
  };
  const handleCloseFormEditIcon = () => {
    setFormIcon(false);
  };

  const handleOpenFormEditMenu = () => {
    setFormMenu(true);
    setFormInfomation(false);
    setFormIcon(false);
    setFormLogo(false);
    setFormSocial(false);
  };
  const handleCloseFormEditMenu = () => {
    setFormMenu(false);
  };

  const handleOpenFormEditSocial = () => {
    setFormSocial(true);
    setFormInfomation(false);
    setFormIcon(false);
    setFormLogo(false);
    setFormMenu(false);
  };
  const handleCloseFormEditSocial = () => {
    setFormSocial(false);
  };

  useEffect(() => {
    const fetchInfoData = async () => {
      const res = await fetch('https://api.chuckmotion.com/header/get-info');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setInfomation(data);
    };
    const fetchMetaData = async () => {
      const res = await fetch('https://api.chuckmotion.com/header/get-meta');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setMeta(data);
    };
    const fetchMenuData = async () => {
      const res = await fetch('https://api.chuckmotion.com/header/get-menu');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setMenu(data);
    };
    const fetchSocialData = async () => {
      const res = await fetch('https://api.chuckmotion.com/header/get-social');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setSocial(data);
    };

    fetchMetaData();
    fetchInfoData();
    fetchMenuData();
    fetchSocialData();
  }, [refreshKey]);

  return (
    <div class="recent-orders">
      <h1>Studio</h1>
      <h2>Danh sách secsion</h2>
      {formInfomation && (
        <>
          <FormInfomation
            info={infomation}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditInfomation}>Đóng</span>
          </div>
        </>
      )}
      {formLogo && (
        <>
          <FormLogo
            logo={meta}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditLogo}>Đóng</span>
          </div>
        </>
      )}
      {formIcon && (
        <>
          <FormIcon
            icon={meta}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditIcon}>Đóng</span>
          </div>
        </>
      )}
      {formMenu && (
        <>
          <FormMenu
            menu={menu}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditMenu}>Đóng</span>
          </div>
        </>
      )}
      {formSocial && (
        <>
          <FormSocial
            item={social}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditSocial}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Thông tin</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditInfomation}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Logo</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditLogo}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Icon Web</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditIcon}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Menu</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditMenu}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Social media</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditSocial}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Header;

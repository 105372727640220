import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../../server/uploads.js';
import cloudinaryDelete from '../../../../server/delete.js';
import * as yup from 'yup';

const schema = yup.object({
  icon: yup.string().required('Không được bỏ trống'),
});

const FormIcon = ({ onRefresh, icon }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImagePublicId, setUploadedImagePublicId] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const form = useForm({
    defaultValues: {
      icon: icon.icon,
      id_icon: icon.id_icon,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchIcon = useCallback(async () => {
    try {
      const res = await fetch(`https://api.chuckmotion.com/header/get-meta`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('icon', data.icon);
      setValue('id_icon', data.id_icon);
    } catch (error) {
      console.error('Error fetching project tag details:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchIcon();
      reset({ ...icon });
    }
  }, [isSubmitSuccessful, fetchIcon, onRefresh, reset, icon]);

  const handleFileUpload = async (e) => {
    setIsLoaded(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrl(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('icon', res.secure_url);
      setValue('id_icon', res.publicId);
      setIsImageUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImage = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrl('');
      setUploadedImagePublicId('');
      setIsImageUploaded(false);
      setValue('icon', '');
      setIsLoaded(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleSubmitProject = async (data) => {
    if (isImageUploaded) {
      cloudinaryDelete(icon.id_icon);
    }
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);
    try {
      const url = `https://api.chuckmotion.com/header/edit-icon`;
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình Ảnh</label>
              <input
                type="file"
                onChange={(e) => handleFileUpload(e)}
                accept="image/*,video/*"
              />
              {errors.icon && <p className="error">{errors.icon.message}</p>}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLoaded ? (
                isImageUploaded && isImageUploaded ? (
                  <div className="form-project-flex-img">
                    <img
                      src={uploadedImageUrl}
                      title="Project Image"
                      alt="Project_Image"
                    />

                    <p onClick={handleDeleteImage}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  <img
                    src={icon.icon}
                    title="Project Image"
                    alt="Project_Image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormIcon;

import React, { useEffect, useState } from 'react';
import FormAddTeam from './FormTeam/FormAddTeam';
import FormEditTeam from './FormTeam/FormEditTeam';
import FormAddBrand from './FormBrand/FormAddBrand';
import FormEditBrand from './FormBrand/FormEditBrand';
import FormAddReview from './FormReview/FormAddReview';
import FormEditReview from './FormReview/FormEditReview';
import FormEditBanner from './FormStuido/FormEditBanner';
import FormEditInfo from './FormStuido/FormEditInfo';
import FormEditDesc from './FormStuido/FormDesc';
import FormEditImage from './FormStuido/FormEditImage';

const Studio = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [team, setTeam] = useState([]);
  const [teamItem, setTeamItem] = useState({});
  const [formAddTeam, setFormAddTeam] = useState(false);
  const [formEditTeam, setFormEditTeam] = useState(false);
  const [brand, setBrand] = useState([]);
  const [brandItem, setBrandItem] = useState({});
  const [formAddBrand, setFormAddBrand] = useState(false);
  const [formEditBrand, setFormEditBrand] = useState(false);
  const [review, setReview] = useState([]);
  const [reviewItem, setReviewItem] = useState({});
  const [formAddReview, setFormAddReview] = useState(false);
  const [formEditReview, setFormEditReview] = useState(false);
  const [banner, setBanner] = useState({});
  const [formEditBanner, setFormEditBanner] = useState(false);
  const [info, setInfo] = useState({});
  const [formEditInfo, setFormEditInfo] = useState(false);
  const [description, setDescription] = useState({});
  const [formEditDesc, setFormEditDesc] = useState(false);
  const [image, setImage] = useState({});
  const [formEditImage, setFormEditImage] = useState(false);

  const handleOpenFormAddTeam = () => {
    setFormAddTeam(true);
  };
  const handleCloseFormAddTeam = () => {
    setFormAddTeam(false);
  };
  const handleOpenFormEditTeam = (item) => {
    setTeamItem(item);
    setFormEditTeam(true);
  };
  const handleCloseFormEditTeam = () => {
    setFormEditTeam(false);
  };

  const handleOpenFormAddBrand = () => {
    setFormAddBrand(true);
  };
  const handleCloseFormAddBrand = () => {
    setFormAddBrand(false);
  };
  const handleOpenFormEditBrand = (item) => {
    setBrandItem(item);
    setFormEditBrand(true);
  };
  const handleCloseFormEditBrand = () => {
    setFormEditBrand(false);
  };

  const handleOpenFormAddReview = () => {
    setFormAddReview(true);
  };
  const handleCloseFormAddReview = () => {
    setFormAddReview(false);
  };
  const handleOpenFormEditReview = (item) => {
    setReviewItem(item);
    setFormEditReview(true);
  };
  const handleCloseFormEditReview = () => {
    setFormEditReview(false);
  };

  const handleOpenFormEditBanner = () => {
    setFormEditBanner(true);
    setFormEditInfo(false);
    setFormEditImage(false);
    setFormEditDesc(false);
  };
  const handleCloseFormEditBanner = () => {
    setFormEditBanner(false);
  };

  const handleOpenFormEditInfo = () => {
    setFormEditInfo(true);
    setFormEditBanner(false);
    setFormEditImage(false);
    setFormEditDesc(false);
  };
  const handleCloseFormEditInfo = () => {
    setFormEditInfo(false);
  };

  const handleOpenFormEditDesc = () => {
    setFormEditDesc(true);
    setFormEditBanner(false);
    setFormEditImage(false);
    setFormEditInfo(false);
  };
  const handleCloseFormEditDesc = () => {
    setFormEditDesc(false);
  };

  const handleOpenFormEditImage = () => {
    setFormEditImage(true);
    setFormEditBanner(false);
    setFormEditInfo(false);
    setFormEditDesc(false);
  };
  const handleCloseFormEditImage = () => {
    setFormEditImage(false);
  };

  useEffect(() => {
    const fetchTeamData = async () => {
      const res = await fetch('https://api.chuckmotion.com/studio/get-team');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setTeam(data);
    };
    const fetchBrandData = async () => {
      const res = await fetch('https://api.chuckmotion.com/studio/get-brand');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBrand(data);
    };
    const fetchReviewData = async () => {
      const res = await fetch('https://api.chuckmotion.com/studio/get-review');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setReview(data);
    };
    const fetchBannerData = async () => {
      const res = await fetch('https://api.chuckmotion.com/studio/get-banner');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBanner(data);
    };
    const fetchInfoData = async () => {
      const res = await fetch('https://api.chuckmotion.com/studio/get-info');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setInfo(data);
    };
    const fetchDescData = async () => {
      const res = await fetch('https://api.chuckmotion.com/studio/get-desc');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setDescription(data);
    };
    const fetchImageData = async () => {
      const res = await fetch('https://api.chuckmotion.com/studio/get-image');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setImage(data);
    };

    fetchTeamData();
    fetchBrandData();
    fetchReviewData();
    fetchBannerData();
    fetchInfoData();
    fetchDescData();
    fetchImageData();
    setFormAddBrand(false);
  }, [refreshKey]);

  const handleDeleteTeam = (item) => {
    console.log(item);
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.chuckmotion.com/studio/delete-team/${item.studio_team_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchExpertise = async () => {
            const res = await fetch(
              'https://api.chuckmotion.com/studio/get-team'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setTeam(data);
          };
          fetchExpertise();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const handleDeleteBrand = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.chuckmotion.com/studio/delete-brand/${item.studio_brand_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchExpertise = async () => {
            const res = await fetch(
              'https://api.chuckmotion.com/studio/get-brand'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setBrand(data);
          };

          fetchExpertise();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const handleDeleteReview = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.chuckmotion.com/studio/delete-review/${item.studio_review_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchExpertise = async () => {
            const res = await fetch(
              'https://api.chuckmotion.com/studio/get-review'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setReview(data);
          };

          fetchExpertise();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div class="recent-orders">
      <h1>Studio</h1>
      <h2>Danh sách secsion</h2>
      {formEditBanner && (
        <>
          <FormEditBanner
            banner={banner}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditBanner}>Đóng</span>
          </div>
        </>
      )}
      {formEditInfo && (
        <>
          <FormEditInfo
            info={info}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditInfo}>Đóng</span>
          </div>
        </>
      )}
      {formEditDesc && (
        <>
          <FormEditDesc
            desc={description}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditDesc}>Đóng</span>
          </div>
        </>
      )}
      {formEditImage && (
        <>
          <FormEditImage
            image={image}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditImage}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Banner Team</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditBanner}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Infomation</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditInfo}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Description</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditDesc}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Image</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditImage}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Danh sách các thành viên</h2>
      {formEditTeam && (
        <>
          <FormEditTeam
            team={teamItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditTeam}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên thành viên</th>
            <th>Avatar</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {team.map((item) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: item.name }} />
              </td>
              <td>
                <img
                  src={item.avatar}
                  alt={item.name}
                  className="project-image"
                />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditTeam(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleDeleteTeam(item)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formAddTeam && (
        <>
          <FormAddTeam onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddTeam}>Đóng</span>
          </div>
        </>
      )}
      <div className="project-add">
        <span onClick={handleOpenFormAddTeam}>Thêm mới</span>
      </div>
      <h2>Danh sách các Brand</h2>
      {formEditBrand && (
        <>
          <FormEditBrand
            brand={brandItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditBrand}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Logo brand</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {brand.map((item) => (
            <tr>
              <td>
                <img
                  src={item.logo}
                  alt={item.name}
                  className="project-image"
                />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditBrand(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleDeleteBrand(item)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formAddBrand && (
        <>
          <FormAddBrand onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddBrand}>Đóng</span>
          </div>
        </>
      )}
      <div className="project-add">
        <span onClick={handleOpenFormAddBrand}>Thêm mới</span>
      </div>
      <h2>Danh sách các Review</h2>
      {formEditReview && (
        <>
          <FormEditReview
            review={reviewItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditReview}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {review.map((item) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: item.name }} />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditReview(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleDeleteReview(item.studio_review_id)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formAddReview && (
        <>
          <FormAddReview onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddReview}>Đóng</span>
          </div>
        </>
      )}
      <div className="project-add">
        <span onClick={handleOpenFormAddReview}>Thêm mới</span>
      </div>
    </div>
  );
};

export default Studio;

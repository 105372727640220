const API_URL = 'https://api.chuckmotion.com';

async function cloudinaryDelete(publicId) {
  try {
    const response = await fetch(
      `${API_URL}/upload-image/cloudinary-delete/${publicId}`,
      {
        method: 'DELETE', // Using DELETE method if possible
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // Kiểm tra nếu phản hồi không thành công
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to delete the image');
    }

    const data = await response.json();
    console.log('Image deleted successfully:', data);
    return data;
  } catch (err) {
    console.error('Error deleting the image:', err.message);
    return { success: false, message: err.message };
  }
}

export default cloudinaryDelete;

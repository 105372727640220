import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  menu_1: yup.string().required('Không được bỏ trống'),
  menu_2: yup.string().required('Không được bỏ trống'),
  menu_3: yup.string().required('Không được bỏ trống'),
  menu_4: yup.string().required('Không được bỏ trống'),
  menu_5: yup.string().required('Không được bỏ trống'),
  menu_6: yup.string().required('Không được bỏ trống'),
});

const FormMenu = ({ menu, onRefresh }) => {
  const form = useForm({
    defaultValues: {
      menu_1: menu.menu_1,
      menu_2: menu.menu_2,
      menu_3: menu.menu_3,
      menu_4: menu.menu_4,
      menu_5: menu.menu_5,
      menu_6: menu.menu_6,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchInfoData = useCallback(async () => {
    try {
      const res = await fetch(`https://api.chuckmotion.com/header/get-menu`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('menu_1', data.menu_1);
      setValue('menu_2', data.menu_2);
      setValue('menu_3', data.menu_3);
      setValue('menu_4', data.menu_4);
      setValue('menu_5', data.menu_5);
      setValue('menu_6', data.menu_6);
    } catch (error) {
      console.error('Failed to fetch project data:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchInfoData();
      reset({ ...menu });
    }
  }, [isSubmitSuccessful, onRefresh, reset, menu, fetchInfoData]);

  const handleSubmitProject = async (data) => {
    try {
      const url = `https://api.chuckmotion.com/header/edit-menu`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('menu_1')}
          />
          {errors.menu_1 && <p className="error">{errors.menu_1.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('menu_2')}
          />
          {errors.menu_2 && <p className="error">{errors.menu_2.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('menu_3')}
          />
          {errors.menu_3 && <p className="error">{errors.menu_3.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('menu_4')}
          />
          {errors.menu_4 && <p className="error">{errors.menu_4.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('menu_5')}
          />
          {errors.menu_5 && <p className="error">{errors.menu_5.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('menu_6')}
          />
          {errors.menu_6 && <p className="error">{errors.menu_6.message}</p>}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormMenu;

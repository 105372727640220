import React, { useEffect, useState } from 'react';
import FormAddExpertise from './FormExpertise/FormAddExpertise';
import FormEditExpertise from './FormExpertise/FormEditExpertise';

const Expertise = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [expertise, setExpertise] = useState([]);
  const [expertiseItem, setExpertiseItem] = useState({});
  const [formExpertise, setFormExpertise] = useState(false);
  const [formEditExpertise, setFormEditExpertise] = useState(false);

  const handleOpenFormExpertise = () => {
    setFormExpertise(true);
  };
  const handleCloseFormExpertise = () => {
    setFormExpertise(false);
  };
  const handleOpenFormEditExpertise = (item) => {
    setExpertiseItem(item);
    setFormEditExpertise(true);
  };
  const handleCloseFormEditExpertise = () => {
    setFormEditExpertise(false);
  };

  useEffect(() => {
    const fetchExpertise = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/expertise/get-expertise'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setExpertise(data);
    };

    fetchExpertise();
    setFormExpertise(false);
  }, [refreshKey]);

  const deleteExpertise = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.chuckmotion.com/expertise/delete-expertise/${item.expertise_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchExpertise = async () => {
            const res = await fetch(
              'https://api.chuckmotion.com/expertise/get-expertise'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setExpertise(data);
          };

          fetchExpertise();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div class="recent-orders">
      <h1>Expertise</h1>
      <h2>Danh sách secsion</h2>
      {formEditExpertise ? (
        <>
          <FormEditExpertise
            exper={expertiseItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditExpertise}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th>Thumbnail</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {expertise &&
            expertise.map((item, index) => (
              <tr>
                <td>
                  <h3 dangerouslySetInnerHTML={{ __html: item.heading }} />
                </td>
                <td>
                  {checkURL(item.thumbnail) ? (
                    <img
                      src={item.thumbnail}
                      title="Project Image"
                      alt="ProjectImage"
                      className="project-image"
                    />
                  ) : isValidYoutubeEmbedUrl(item.thumbnail) ? (
                    <iframe
                      src={item.thumbnail}
                      title="Project Video"
                      alt="ProjectImage"
                      className="project-image"
                    />
                  ) : (
                    <iframe
                      src={item.thumbnail}
                      title="Project Video"
                      alt="ProjectImage"
                      className="project-image"
                    />
                  )}
                </td>
                <td>
                  <span
                    className="project-actions"
                    onClick={() => handleOpenFormEditExpertise(item)}>
                    Chi tiết
                  </span>
                </td>
                <td>
                  <span
                    className="project-actions"
                    onClick={() => deleteExpertise(item)}>
                    Xóa
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {formExpertise ? (
        <>
          <FormAddExpertise onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormExpertise}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormExpertise}>Thêm mới</span>
      </div>
    </div>
  );
};

export default Expertise;

import React, { useEffect, useState } from 'react';
import FormKeyVisual from './FormWork/FormKeyVisual';
import FormInformation from './FormWork/FormInformation';

const Work = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [keyVisual, setKeyVisual] = useState({});
  const [information, setInformation] = useState({});
  const [formKeyVisual, setFormKeyVisual] = useState(false);
  const [formInfo, setFormInfo] = useState(false);

  const handleOpenFormKeyVisual = () => {
    setFormKeyVisual(true);
    setFormInfo(false);
  };
  const handleCloseFormKeyVisual = () => {
    setFormKeyVisual(false);
  };

  const handleOpenFormInfo = () => {
    setFormInfo(true);
    setFormKeyVisual(false);
  };

  const handleCloseFormInfo = () => {
    setFormInfo(false);
  };

  useEffect(() => {
    const fetchKeyVisual = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/work/get-key-visual'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setKeyVisual(data);
    };
    const fetchsetInformation = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/work/work-information'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setInformation(data);
    };

    fetchKeyVisual();
    fetchsetInformation();
  }, [refreshKey]);

  return (
    <div class="recent-orders">
      <h1>Expertise</h1>
      <h2>Danh sách secsion</h2>
      {formKeyVisual ? (
        <>
          <FormKeyVisual
            keyVisual={keyVisual}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormKeyVisual}>Đóng</span>
          </div>
        </>
      ) : null}
      {formInfo ? (
        <>
          <FormInformation
            info={information}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormInfo}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Key visual</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormKeyVisual}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Infomation</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormInfo}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Work;

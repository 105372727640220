import React, { useEffect, useState } from 'react';
import FormAddProcess from './FormProcess/FormAddProcess';
import FormEditProcess from './FormProcess/FormEditProcess';
import FormAddQnA from './FormQnA/FormAddQnA';
import FormEditQnA from './FormQnA/FormEditQnA';
import FormProcessHeading from './FormHeading/FormProcessHeading';
import FormQnAHeading from './FormHeading/FornQnAHeading';
import FormBanner from './FormBanner/FormBanner';

const Process = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [process, setProcess] = useState([]);
  const [qna, setQna] = useState([]);
  const [processHeading, setProcessHeading] = useState({});
  const [processDetail, setProcessDetail] = useState({});
  const [qnaDetail, setQnaDetail] = useState({});
  const [processBanner, setProcessBanner] = useState({});
  const [FormProcess, setFormProcess] = useState(false);
  const [FormEditItemProcess, setFormEditItemProcess] = useState(false);
  const [FormQna, setFormQna] = useState(false);
  const [FormEditItemQna, setFormEditItemQna] = useState(false);
  const [formNameProcess, setFormNameProcess] = useState(false);
  const [formNameQnA, setFormNameQnA] = useState(false);
  const [formBanner, setFormBanner] = useState(false);

  const handleOpenFormAddProcess = () => {
    setFormProcess(true);
  };
  const handleCloseFormAddProcess = () => {
    setFormProcess(false);
  };

  const handleOpenFormEditProcess = (item) => {
    setProcessDetail(item);
    setFormEditItemProcess(true);
  };
  const handleCloseFormEditProcess = () => {
    setFormEditItemProcess(false);
  };

  const handleOpenFormAddQna = () => {
    setFormQna(true);
  };
  const handleCloseFormAddQna = () => {
    setFormQna(false);
  };

  const handleOpenFormEditQna = (item) => {
    setQnaDetail(item);
    setFormEditItemQna(true);
  };
  const handleCloseFormEditQna = () => {
    setFormEditItemQna(false);
  };

  const handleOpenFormHeadingProcess = () => {
    setFormNameProcess(true);
    setFormNameQnA(false);
    setFormBanner(false);
  };
  const handleCloseFormHeadingProces = () => {
    setFormNameProcess(false);
  };

  const handleOpenFormHeadingQna = () => {
    setFormNameQnA(true);
    setFormNameProcess(false);
    setFormBanner(false);
  };
  const handleCloseFormHeadingQna = () => {
    setFormNameQnA(false);
  };

  const handleOpenFormBanner = () => {
    setFormBanner(true);
    setFormNameProcess(false);
    setFormNameQnA(false);
  };
  const handleCloseFormBanner = () => {
    setFormBanner(false);
  };

  useEffect(() => {
    const fetchProcessData = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/process/get-process'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setProcess(data);
    };
    const fetchQnaData = async () => {
      const res = await fetch('https://api.chuckmotion.com/process/get-qna');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setQna(data);
    };
    const fetchHeading = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/process/get-process-heading'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setProcessHeading(data);
    };
    const fetchBanner = async () => {
      const res = await fetch('https://api.chuckmotion.com/process/get-banner');
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setProcessBanner(data);
    };

    fetchProcessData();
    fetchQnaData();
    fetchHeading();
    fetchBanner();

    setFormProcess(false);
    setFormQna(false);
  }, [refreshKey]);

  const handleDeleteProject = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(
        `https://api.chuckmotion.com/process/delete-process/${item.process_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchExpertise = async () => {
            const res = await fetch(
              'https://api.chuckmotion.com/process/get-process'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setProcess(data);
          };

          fetchExpertise();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const handleDeleteQna = (item) => {
    if (window.confirm('Bạn muốn xóa này không?')) {
      fetch(`https://api.chuckmotion.com/process/delete-qna/${item.qna_id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchExpertise = async () => {
            const res = await fetch(
              'https://api.chuckmotion.com/process/get-qna'
            );
            const text = await res.text();
            const data = text ? JSON.parse(text) : {};
            setProcess(data);
          };

          fetchExpertise();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div class="recent-orders">
      <h1>Process</h1>
      <h2>Danh sách secsion</h2>
      {formNameProcess && (
        <>
          <FormProcessHeading
            process={processHeading}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormHeadingProces}>Đóng</span>
          </div>
        </>
      )}
      {formNameQnA && (
        <>
          <FormQnAHeading
            qna={processHeading}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormHeadingQna}>Đóng</span>
          </div>
        </>
      )}
      {formBanner && (
        <>
          <FormBanner
            banner={processBanner}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormBanner}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Banner</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormBanner}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                dangerouslySetInnerHTML={{
                  __html: processHeading.name_process,
                }}
              />
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormHeadingProcess}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                dangerouslySetInnerHTML={{
                  __html: processHeading.name_qna,
                }}
              />
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormHeadingQna}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Danh sách process</h2>
      {FormEditItemProcess && (
        <>
          <FormEditProcess
            process={processDetail}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditProcess}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên tiêu đề</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {process.map((item) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: item.heading }} />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditProcess(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleDeleteProject(item)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {FormProcess ? (
        <>
          <FormAddProcess onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddProcess}>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-add">
        <span onClick={handleOpenFormAddProcess}>Thêm mới</span>
      </div>
      <h2>Danh sách Q&N</h2>
      {FormEditItemQna && (
        <>
          <FormEditQnA
            qna={qnaDetail}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditQna}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên tiêu đề</th>
            <th>Hình ảnh</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {qna.map((item) => (
            <tr>
              <td>
                <h3 dangerouslySetInnerHTML={{ __html: item.heading }} />
              </td>
              <td>
                <img
                  src={item.thumbnail}
                  alt={item.id_thumbnail}
                  className="project-image"
                />
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditQna(item)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleDeleteQna(item)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {FormQna && (
        <>
          <FormAddQnA onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div className="project-add">
            <span onClick={handleCloseFormAddQna}>Đóng</span>
          </div>
        </>
      )}
      <div className="project-add">
        <span onClick={handleOpenFormAddQna}>Thêm mới</span>
      </div>
    </div>
  );
};

export default Process;

import React, { useEffect, useState } from 'react';
import cloudinaryDelete from '../../../server/delete.js';
import FormEditProject from '../Projects/FormProject/FormEditProject';
import { useParams } from 'react-router-dom';
import FormAddDetail from './FormProductDetail/FormAddDetail';
import FormEditDetail from './FormProductDetail/FormEditDetail.jsx';

const ProjectDetail = () => {
  let { id } = useParams();
  const [refreshKey, setRefreshKey] = useState(0);
  const [openFormProject, setOpenFormProject] = useState(false);
  const [openFormAddDetail, setOpenFormAddDetail] = useState(false);
  const [openFormEditDetail, setOpenFormEditDetail] = useState(false);
  const [projectDetailItem, setProjectDetailItem] = useState({});
  const [projectSimble, setProjectSimble] = useState({});
  const [projectDetail, setProjectDetail] = useState({});
  const [listDetail, setListDetail] = useState([]);

  const handleOpenFormProject = (project) => {
    setOpenFormProject(true);
    setProjectSimble(project);
  };
  const handleCloseFormProject = () => {
    setOpenFormProject(false);
  };

  const handleOpenFormAddDetail = () => {
    setOpenFormAddDetail(true);
  };
  const handleCloseFormAddDetail = () => {
    setOpenFormAddDetail(false);
  };

  const handleOpenFormEditDetail = (project) => {
    setOpenFormEditDetail(true);
    setProjectDetailItem(project);
  };
  const handleCloseFormEditDetail = () => {
    setOpenFormEditDetail(false);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chuckmotion.com/projects/get-project-detail/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setListDetail(data);
    };
    fetchProjects();
    setOpenFormAddDetail(false);
  }, [refreshKey, id]);

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chuckmotion.com/projects/get-project/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setProjectDetail(data);
    };
    fetchProjects();
  }, [refreshKey, id]);

  const deleteProject = (project) => {
    if (window.confirm('Bạn muốn xóa chi tiết này này không?')) {
      fetch(
        `https://api.chuckmotion.com/projects/delete-project-detail/${project.project_detail_id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chuckmotion.com/projects/get-project-detail/${id}`
            );
            const data = await res.json();
            setListDetail(data);
          };
          cloudinaryDelete(project.id_image);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div className="recent-orders">
      <h1>Chi tiết Dự án</h1>
      <h2>Thông tin cơ bản</h2>
      {openFormProject ? (
        <>
          <FormEditProject
            project={projectSimble}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormProject}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Tên dự án</th>
            <th>Hình thumbnail</th>
            <th>Trạng thái</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div
                dangerouslySetInnerHTML={{ __html: projectDetail.heading }}
              />
            </td>
            <td>
              <img
                src={projectDetail.thumbnail}
                title="Project Image"
                alt="ProjectImage"
                className="project-image"
              />
            </td>
            <td>
              {projectDetail.status === 1
                ? 'Hoàn thành'
                : projectDetail.status === 2
                ? 'Ẩn'
                : 'Coming soon'}
            </td>
            <td>
              <span
                className="project-actions"
                onClick={() => handleOpenFormProject(projectDetail)}>
                Sửa
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Nội dung dự án</h2>
      {openFormEditDetail ? (
        <>
          <FormEditDetail
            detail={projectDetailItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditDetail}>Đóng</span>
          </div>
        </>
      ) : null}
      <table>
        <thead>
          <tr>
            <th>Hình ảnh</th>
            <th>Nội dung</th>
            <th>Layout</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {listDetail.map((detail) => (
            <tr key={detail.id}>
              <td>
                <img
                  src={detail.image}
                  title="Project Image"
                  alt="ProjectImage"
                  className="project-image"
                />
              </td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: detail.content }} />
              </td>
              <td>
                {detail.layout === 0
                  ? 'Ảnh - Text'
                  : detail.layout === 1
                  ? 'Text - Ảnh'
                  : detail.layout === 2
                  ? 'Ảnh'
                  : 'Text'}
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => handleOpenFormEditDetail(detail)}>
                  Chi tiết
                </span>
              </td>
              <td>
                <span
                  className="project-actions"
                  onClick={() => deleteProject(detail)}>
                  Xóa
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="project-add">
        <span onClick={handleOpenFormAddDetail}>Thêm dự chi tiết</span>
      </div>
      {openFormAddDetail ? (
        <>
          <FormAddDetail
            project_id={id}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormAddDetail}>Đóng</span>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ProjectDetail;

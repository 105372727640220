import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../../server/uploads.js';
import cloudinaryDelete from '../../../../server/delete.js';
import * as yup from 'yup';

const schema = yup.object({
  image_1: yup.string().required('Không được bỏ trống'),
  image_2: yup.string().required('Không được bỏ trống'),
  image_3: yup.string().required('Không được bỏ trống'),
  image_4: yup.string().required('Không được bỏ trống'),
  image_5: yup.string().required('Không được bỏ trống'),
  image_6: yup.string().required('Không được bỏ trống'),
  image_7: yup.string().required('Không được bỏ trống'),
  image_8: yup.string().required('Không được bỏ trống'),
});

const FormEditImage = ({ image, onRefresh }) => {
  const [uploadedImages, setUploadedImages] = useState(Array(8).fill(''));
  const [uploadedImagePublicIds, setUploadedImagePublicIds] = useState(
    Array(8).fill('')
  );
  const [isImageUploaded, setIsImageUploaded] = useState(Array(8).fill(false));
  const [isLoaded, setIsLoaded] = useState(Array(8).fill(false));

  const form = useForm({
    defaultValues: {
      image_1: image.image_1,
      image_2: image.image_2,
      image_3: image.image_3,
      image_4: image.image_4,
      image_5: image.image_5,
      image_6: image.image_6,
      image_7: image.image_7,
      image_8: image.image_8,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchBanner = useCallback(async () => {
    try {
      const res = await fetch(`https://api.chuckmotion.com/stuido/get-image`);
      const data = await res.json();
      for (let i = 1; i <= 8; i++) {
        setValue(`image_${i}`, data[`image_${i}`]);
      }
    } catch (error) {
      console.error('Error fetching project tag details:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchBanner();
      reset(image);
    }
  }, [isSubmitSuccessful, fetchBanner, onRefresh, reset, image]);

  const handleFileUpload = async (e, index) => {
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);
    const updatedIsLoaded = [...isLoaded];
    updatedIsLoaded[index] = true;
    setIsLoaded(updatedIsLoaded);

    try {
      const res = await cloudinaryUpload(fileData);
      const updatedUploadedImages = [...uploadedImages];
      const updatedUploadedImagePublicIds = [...uploadedImagePublicIds];
      updatedUploadedImages[index] = res.secure_url;
      updatedUploadedImagePublicIds[index] = res.publicId;
      setUploadedImages(updatedUploadedImages);
      setUploadedImagePublicIds(updatedUploadedImagePublicIds);
      setValue(`image_${index + 1}`, res.secure_url);
      setValue(`id_image_${index + 1}`, res.publicId);
      const updatedIsImageUploaded = [...isImageUploaded];
      updatedIsImageUploaded[index] = true;
      setIsImageUploaded(updatedIsImageUploaded);
    } catch (err) {
      console.error('Upload Error:', err);
      alert('Failed to upload image');
    }
  };

  const handleDeleteImage = async (index) => {
    if (!uploadedImagePublicIds[index]) {
      alert('No image to delete');
      return;
    }
    try {
      await cloudinaryDelete(uploadedImagePublicIds[index]);
      const updatedUploadedImages = [...uploadedImages];
      const updatedUploadedImagePublicIds = [...uploadedImagePublicIds];
      const updatedIsImageUploaded = [...isImageUploaded];
      const updatedIsLoaded = [...isLoaded];
      updatedUploadedImages[index] = '';
      updatedUploadedImagePublicIds[index] = '';
      updatedIsImageUploaded[index] = false;
      updatedIsLoaded[index] = false;
      setUploadedImages(updatedUploadedImages);
      setUploadedImagePublicIds(updatedUploadedImagePublicIds);
      setIsImageUploaded(updatedIsImageUploaded);
      setIsLoaded(updatedIsLoaded);
      setValue(`image_${index + 1}`, '');
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleSubmitProject = async (data) => {
    for (let i = 0; i < 8; i++) {
      if (isImageUploaded[i]) {
        await cloudinaryDelete(image[`id_image_${i + 1}`]);
      }
    }

    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        (char) => `&#x${char.codePointAt(0).toString(16)};`
      );
    }

    function convertObjectToUnicode(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]);
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]);
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);
    try {
      const url = `https://api.chuckmotion.com/studio/edit-image`;
      const opt = {
        method: 'PUT',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const responseData = await res.json();
      alert('Xác nhận thành công,', responseData);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
      alert('Failed to submit the form');
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        {[...Array(8)].map((_, index) => (
          <div
            className="form-project-item"
            key={index}>
            <div className="form-project-flex">
              <div className="form-project-flex-item">
                <label>{`Hình Ảnh ${index + 1}`}</label>
                <input
                  type="file"
                  onChange={(e) => handleFileUpload(e, index)}
                  accept="image/*,video/*"
                />
                {errors[`image_${index + 1}`] && (
                  <p className="error">
                    {errors[`image_${index + 1}`].message}
                  </p>
                )}
              </div>
              <div className="form-project-flex-item">
                {isLoaded[index] ? (
                  isImageUploaded[index] ? (
                    <div className="form-project-flex-img">
                      <img
                        src={uploadedImages[index]}
                        title="Project Image"
                        alt="Project_Image"
                      />
                      <p onClick={() => handleDeleteImage(index)}>Xóa</p>
                    </div>
                  ) : (
                    <p className="project-loading">Loading ...</p>
                  )
                ) : (
                  <div className="form-project-flex-img">
                    <img
                      src={image[`image_${index + 1}`]}
                      title="Project Image"
                      alt="Project_Image"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="form-project-item">
          <button type="submit">Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormEditImage;

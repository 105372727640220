import React, { useEffect, useState } from 'react';
import FormContent from './FormEnquiries/FormContent';
import FormBudget from './FormEnquiries/FormBudget';
import FormService from './FormEnquiries/FormService';

const Enquiries = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [content, setContent] = useState({});
  const [fomrContent, setFormContent] = useState(false);
  const [budget, setBudget] = useState({});
  const [formBudget, setFormBudget] = useState(false);
  const [service, setService] = useState({});
  const [formService, setFormService] = useState(false);

  const handleOpenFormEditContent = () => {
    setFormContent(true);
    setFormBudget(false);
    setFormService(false);
  };
  const handleCloseFormEditContent = () => {
    setFormContent(false);
  };

  const handleOpenFormEditBudget = () => {
    setFormBudget(true);
    setFormContent(false);
    setFormService(false);
  };
  const handleCloseFormEditBudget = () => {
    setFormBudget(false);
  };

  const handleOpenFormEditService = () => {
    setFormService(true);
    setFormContent(false);
    setFormBudget(false);
  };
  const handleCloseFormEditService = () => {
    setFormService(false);
  };

  useEffect(() => {
    const fetchContentData = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/enquiries/get-content'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setContent(data);
    };
    const fetchBudgetData = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/enquiries/get-budget'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBudget(data);
    };
    const fetchServiceData = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/enquiries/get-service'
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setService(data);
    };

    fetchContentData();
    fetchBudgetData();
    fetchServiceData();
  }, [refreshKey]);

  return (
    <div class="recent-orders">
      <h1>Enquiries</h1>
      <h2>Danh sách secsion</h2>
      {fomrContent && (
        <>
          <FormContent
            content={content}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditContent}>Đóng</span>
          </div>
        </>
      )}
      {formBudget && (
        <>
          <FormBudget
            budget={budget}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditBudget}>Đóng</span>
          </div>
        </>
      )}
      {formService && (
        <>
          <FormService
            service={service}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div className="project-add">
            <span onClick={handleCloseFormEditService}>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên Secsion</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          <tr>
            <td>
              <h3>Content</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditContent}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Budget</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditBudget}>
                Chi tiết
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Service</h3>
            </td>
            <td></td>
            <td>
              <span
                className="project-actions"
                onClick={handleOpenFormEditService}>
                Chi tiết
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Enquiries;

import gsap from 'gsap';

// Declare a general timeline to use in all the animation functions.

const tl = gsap.timeline();

export const preLoaderAnim = () => {
  tl.to('.loading-img', {
    duration: 0.5,
    opacity: 1,
    ease: 'Power3.easeOut',
  })
    .to('.loading', {
      duration: 0.8,
      delay: 2.35,
      css: { overflowY: 'hidden', height: '100vh', opacity: 0 },
      ease: 'Power3.easeOut',
    })
    .to('.loading', {
      duration: 0,
      css: { display: 'none' },
    });
};
